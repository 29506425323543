import React from 'react';
import PropTypes from 'prop-types';

export default function NotFound({ message, statusCode }) {
  return (
    <div className="not-found">
      <h2 className="not-found__title">
        {statusCode}: {message}
      </h2>
    </div>
  );
}

NotFound.defaultProps = {
  message: 'The page you are looking for cannot be found.',
  statusCode: 404
};

NotFound.propTypes = {
  message: PropTypes.string,
  statusCode: PropTypes.number
};
